import {
  Cards,
  GetPagesQuery,
  Heroes,
  Images,
  Markdown,
  Rich_Texts,
  Subtitles,
  Texts_With_Images,
  Titles,
  Unarray,
  getTranslation,
  localeToMarketCode,
} from '@instabox/consumer-web-shared'
import { Card, Heading, Stack } from '@instabox/consumer-web-ui'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
import { TextComponentProps } from '@instabox/consumer-web-ui/dist/types'

import HeroImage from '../HeroImage'
import PageHeading from '../PageHeading'
import TextWithImage, { Layout } from '../TextWithImage'

export type Field =
  | Cards['__typename']
  | Titles['__typename']
  | Subtitles['__typename']
  | Rich_Texts['__typename']
  | Texts_With_Images['__typename']
  | Images['__typename']
  | Heroes['__typename']

export type PageContentProps = Pick<Unarray<GetPagesQuery['pages']>, 'content'> &
  Pick<Unarray<GetPagesQuery['pages']>, 'slug'> & {
    fields?: Field[]
    textsWithImagesLayout?: Layout
    richTextAlign?: TextComponentProps['align']
    children?: ReactNode | ReactNode[]
  }

const PageContent: FC<PageContentProps> = ({
  content,
  slug,
  fields,
  textsWithImagesLayout,
  richTextAlign = 'left',
  children,
}) => {
  const { locale } = useRouter()
  return (
    <>
      {content
        ?.filter(({ item }) => (fields?.length > 0 ? fields.includes(item?.__typename) : true))
        .map(({ item }) => {
          switch (item?.__typename) {
            case 'titles':
              return (
                <PageHeading key={`title-${item.id}`}>
                  <Link href={`/${slug}`} className="no-underline">
                    {getTranslation('title', item.translations, item.title)}
                  </Link>
                </PageHeading>
              )
            case 'subtitles':
              return (
                <Heading color="white" as="h6" align="center" key={`subtitle-${item.id}`}>
                  {getTranslation('subtitle', item.translations, item.subtitle)}
                </Heading>
              )
            case 'cards':
              if (
                !item.markets.some(
                  market => market.markets_code?.code === localeToMarketCode(locale),
                )
              ) {
                return null
              }
              return (
                <Card key={`card-${item.id}`}>
                  <Stack gap="md">
                    <Heading as="h6">
                      {getTranslation('header', item.translations, item.header)}
                    </Heading>
                    <Markdown>{getTranslation('text', item.translations, item.text)}</Markdown>
                  </Stack>
                </Card>
              )
            case 'rich_texts':
              return (
                <Markdown color="white" key={`rich-text-${item.id}`} align={richTextAlign}>
                  {getTranslation('rich_text', item.translations, item.rich_text)}
                </Markdown>
              )
            case 'texts_with_images':
              return (
                // @ts-ignore
                <TextWithImage
                  key={`texts-with-images-${item.id}`}
                  {...item}
                  layout={textsWithImagesLayout}
                />
              )
            case 'images': {
              const { translations, image } = item
              if (!image) return null
              const translatedImage = translations[0]?.image || image
              return (
                <Image
                  key={`images-${translatedImage?.id}`}
                  src={`${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${translatedImage?.id}`}
                  alt={translatedImage?.title}
                  width={translatedImage?.width}
                  height={translatedImage?.height}
                />
              )
            }
            case 'heroes':
              return (
                <HeroImage
                  key={`heroes-${item.id}`}
                  image={`${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${item.background_image?.id}`}
                  blend_mode={item.blend_mode}
                  content_position={item.content_position}
                  fullscreen={item.fullscreen}
                  background_color={item.background_color}>
                  {children}
                </HeroImage>
              )
            default:
              return null
          }
        })}
    </>
  )
}

export default PageContent
